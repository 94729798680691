/*
@contract tour-wizard {
  heading-color: Color
  text-color: Color
  accent-color: Color
  accent-color-light: Color
  accent-color-hover: Color
}
*/

.tourWizardModal {
  --modal-padding: 0;
  --modal-panel-wrap-height: 100%;
  --modal-panel-display: flex;
  --modal-panel-flex-direction: column;
  --spinner-size: 40px;
  --spinner-speed: 700ms;

  @media (--screen-md) {
    --modal-margin: auto;
    --modal-width: 728px;
    --modal-padding: 0;
    --modal-height: 746px;
  }
}

.tourWizardModalContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  font-size: var(--font-size-body);
}

.tourTimesStepDayName {
  text-transform: uppercase;
  display: block;
}

.tourTimesStepDayNumber {
  display: block;
  font-weight: var(--font-weight-semibold);
  color: var(--tour-wizard-heading-color);
  font-size: var(--font-size-xl);
  line-height: var(--leading-tight);
  margin-bottom: var(--space-5x);
}

.tourTimesStepContainer {
  padding: 0 var(--space-4x);

  @media (--screen-md) {
    margin: 0 auto;
    width: 80%;
    padding: 0;
  }
}

.tourTimesScrollContainer {
  flex: 1 1 0;
  overflow-y: auto;
  padding-bottom: var(--space-3x);
}

.tourTimesNavContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: hidden;
  position: relative;
}

.stepHeading {
  color: var(--heading-color);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
}

.stepSubHeading {
  margin-top: var(--space-3x);
}

.header {
  z-index: var(--z-index-1x);
  display: flex;
  background: white;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.headerShowBack {
  justify-content: space-between;
}

.headerHideBack {
  justify-content: flex-end;
}

.headerShowClose {
  height: 60px;
  padding: 20px 24px;
  margin-bottom: var(--space-3x);
}

.headerHideClose {
  height: 0;
  margin-bottom: 0;
}
.tourScheduledTime {
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
}
.headerButton {
  --anchor-font-size: var(--font-size-body);
  display: flex;
  align-items: center;
  gap: var(--space-1-5x);
  padding: 0;
  margin: var(--anchor-padding);
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.stepContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
}

.footerContainer {
  z-index: var(--z-index-1x);
  flex-grow: 0;
  box-shadow: var(--shadow-100-up);
  background-color: var(--color-white);
}

.footerContainerInner {
  padding: var(--space-4x) var(--space-6x);

  @media (--screen-sm) {
    margin: 0 auto;
    width: 80%;
    padding: var(--space-4x);
  }
}

.nav {
  position: relative;
  flex-grow: 0;
  padding-top: var(--space-6x);
  padding-bottom: var(--space-2-5x);
}

.tourTimesNavNext,
.tourTimesNavBack {
  position: absolute;
  top: 0;
  padding: var(--space-6x) var(--space-2-5x);
  font-size: var(--font-size-xl);
  color: var(--tour-wizard-accent-color);
  margin-top: var(--space-2x);
}

.tourTimesNavBack {
  left: var(--space-3x);
}

.tourTimesNavNext {
  right: var(--space-3x);
}

.day {
  min-width: 90px;
  text-align: center;
}

.dateGrid {
  list-style: none;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  padding: 0 var(--space-5x);

  @media (--screen-sm) {
    margin: 0 auto;
    width: 80%;
    padding: 0;
  }
}

.dateGridWithOne {
  @media (--screen-sm) {
    justify-content: center;
  }
}

.dateGridWithMany {
  @media (--screen-sm) {
    justify-content: space-between;
  }
}

.timeList {
  padding: 0;
  list-style: none;
}

.timeList > :not([hidden]) ~ :not([hidden]) {
  margin-top: var(--space-3x);
}

.timeListSingleTime {
  width: 100%;
}

.timeListItem {
  position: relative;
}

.tourTimeSelection {
  display: block;
  font-weight: var(--font-weight-semibold);
  text-align: center;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius-2x);
  padding: 10px 0;
  min-width: 105px;
  width: 100%;
  color: var(--tour-wizard-text-color);
  cursor: pointer;

  &:hover {
    background: var(--tour-wizard-accent-color-light);
  }
}

.tourTimeSelectionInput {
  opacity: 0;
  position: absolute;

  &[disabled] + .tourTimeSelection {
    border: 1px solid var(--color-gray-200);
    color: var(--color-gray-300);
    cursor: not-allowed;
  }

  &:checked + .tourTimeSelection {
    background: var(--tour-wizard-accent-color);
    color: var(--color-white);
    cursor: pointer;
    border-color: 1px solid var(--tour-wizard-accent-color);

    &:hover {
      background: var(--tour-wizard-accent-color-hover);
    }
  }
}

.tourTypesListWrapper {
  margin-top: var(--space-6x);
}

.tourTypesList {
  list-style: none;
  padding: var(--space-0-5x);
  background: var(--color-gray-100);
  justify-items: center;
  display: flex;
  border-radius: var(--border-radius-2x);
}

.tourTypesListItem {
  position: relative;
  width: 100%;
  display: inline-flex;
  border-radius: var(--border-radius-2x);
  color: var(--tour-wizard-accent-color);

  &:not(.tourTypesListItemSelected):hover {
    background: var(--color-indigo-200);
  }
}

.pipe::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: var(--space-2x);
  width: var(--space-px);
  height: var(--space-6x);
  background: var(--color-gray-300);
}

.tourTypesListLabel {
  line-height: var(--leading-tight);
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  min-height: 0;
  position: relative;
  border-radius: var(--border-radius-2x);
}

.tourTypesListHeading {
  text-align: center;
  width: 100%;
}

.tourTypesInput {
  &:checked + .tourTypesListLabel {
    background: var(--tour-wizard-accent-color);
    color: white;
  }
}

.tourTypesListHeadingDisabled {
  color: var(--color-gray-300);
}

.contactFormBase {
  gap: 20px;
  padding-top: 20px;
  background: white;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (--screen-sm) {
    flex-wrap: wrap;
  }
}

.contactFormNameGroup {
  display: flex;
  width: 100%;
  background: white;
  flex-direction: column;
  gap: 20px;

  @media (--screen-sm) {
    flex-direction: row;
  }
}

.contactFormLastName,
.contactFormFirstName {
  width: 100%;

  @media (--screen-sm) {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}

.contactFormFirstName {
  @media (--screen-sm) {
    padding-right: 10px;
  }
}

.contactFormLastName {
  @media (--screen-sm) {
    padding-left: 10px;
  }
}

.contactFormInput {
  width: 100%;
  @media (--screen-sm) {
    display: flex;
    flex-direction: column;
  }
}

.contactStepWrapper {
  overflow: auto;
}

.contactStepBase {
  padding: 24px;

  @media (--screen-sm) {
    margin: 0 auto;
    width: 80%;
    padding-left: 0;
    padding-right: 0;
  }
}

.contactStepHeading {
  margin-top: 24px;
  color: black;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
}

.thankYouStepWrapper {
  align-items: center;
  overflow: auto;
  padding: 75px 20px 20px;
  text-align: center;
}

.thankYouStepSpacing {
  margin: 0 30px 25px;
}

.thankYouStepConfirmationText {
  font-weight: bold;
}

.thankYouStepCalendarIcons {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @media (--screen-sm) {
    margin: 0 auto;
    width: 300px;
  }
}

.thankYouStepCalendarButtonsText {
  min-width: 175px;
}

.thankYouStepCalendarIcons .thankYouStepCalendarButtonsGoogle {
  margin-bottom: 10px;

  @media (--screen-md) {
    margin-bottom: 15px;
  }
}

.thankYouStepCalendarButtonsApple {
  width: 100%;
}

.thankYouStepCalendarIcon {
  margin-right: 10px;
  margin-top: -2px;
}

.failureImage {
  width: 128px;
  margin-bottom: 25px;
}

.thankYouHeading {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
  line-height: var(--leading-snug);
  margin-bottom: 25px;

  @media (--screen-md) {
    font-size: var(--font-size-2xl);
  }
}

.thankYouImage {
  display: block;
  width: 128px;
  margin-bottom: 25px;
}

.tourTypeHeading {
  margin-bottom: 32px;
  color: var(--color-black);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
}

.tryAgainButton {
  margin-top: 40px;
  padding: 0 20px;
  width: 200px;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-2x);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  text-align: center;
}

.loadingText {
  margin-top: var(--space-3x);
}
